import React from "react";
import Layout from "../components/layout"
import "./index.scss"

export default () => (
  <Layout pageId={"/ren-lian-shi-bie"}>
{/*
    <div className="container-fluid bg-wave-top">
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <h1 style={{color:'white', fontWeight:'bold', fontStyle:'italic'}}>
              通行与访客
            </h1>
            <p style={{color: 'white'}}>
              随着智能手机的全面普及和人工智能技术在人脸识别领域的成熟应用，传统的<span className="text-emphasize">钥匙和感应卡正在被全面淘汰</span>。基于人脸识别和智能手机的身份识别系统在<span className="text-emphasize">门禁、道闸、访客机、梯控</span>等场景中正在快速取代传统的通行方式。<span className="text-emphasize">人脸识别开门和手机开门</span>正在成为当下最潮的科技。
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/2.png" className="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
*/}
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            人脸识别一体机G8系列
          </h1>
          <h4>
            人脸识别门禁一体机
          </h4>
          <h4>
            人证核验一体机
          </h4>
          <h4>
            人脸识别+测温门禁一体机
          </h4>
          <h4>
            人证核验+测温一体机
          </h4>
          所有产品均有WiFi版 / 4G双版本
{/*
          <ul>
            <li><h4>人脸识别门禁一体机</h4></li>
            <li><h4>人证核验一体机</h4></li>
            <li><h4>人脸识别+测温门禁一体机</h4></li>
            <li><h4>人证核验+测温一体机</h4></li>
          </ul>
*/}
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/S8.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              北京健康宝
            </h1>
            <p>
              - 直接获取北京经信委大数据信息<br/>
              - 获取绿码、黄码、红码信息，告警
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/6.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            - 识别准确，稳定可靠
          </h1>
          <p>
            - 独有测温算法，解决人脸测温不准确的问题<br/>
            - 独有自适应识别算法，有效提高识别率<br/>
            - 离线识别，稳定可靠<br/>
            - 内嵌百度AI，大厂品质，值得信赖
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/chahua/2_1.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              - 管理便捷，灵活响应
            </h1>
            <p>
              - 云同步权限，随时添加、删除、冻结，强大权限管理，不需要手动往设备上导入信息<br/>
              - 管理员录入或者用户自己填写管理员审核，适应各种场景<br/>
              - OA数据对接
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/1.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            - 独有功能
          </h1>
          <p>
            - 支持4G，快速安装，只需要电源，无需网络布线，大大降低实施成本<br/>
            - 支持手机蓝牙开门，适应个别人脸无法识别情况<br/>
            - 支持手机远程开门
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/community/2.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </Layout>
);
